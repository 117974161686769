import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectBranch, updateLocation } from "../redux/actions/branchFinder";
// import SmoothScrollLink from "../components/custom/SmoothScrollLink";

function Footer() {
  const dispatch = useDispatch();
  const { branches, selectedBranch, location } = useSelector(
    (state) => state.branchFinder
  );

  const getCurrentYear = () => new Date().getFullYear();

  const handleBranchChange = (e) => {
    dispatch(selectBranch(e.target.value));
  };

  const handleLocationChange = (e) => {
    dispatch(updateLocation(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedBranch) {
      const selectedCounty = Object.keys(branches).find((county) =>
        branches[county].some((branch) => branch.name === selectedBranch)
      );
      const branchInfo = branches[selectedCounty].find(
        (branch) => branch.name === selectedBranch
      );
      const mapUrl = `https://www.google.com/maps/search/${encodeURIComponent(
        branchInfo.address
      )}`;
      window.open(mapUrl, "_blank");
    } else {
      alert("Please select a branch");
    }
  };

  return (
    <footer className="main-footer">
      <div className="widget-section">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{
              backgroundImage: "url(assets/images/shape/shape-8.png)",
            }}
          ></div>
          <div
            className="pattern-2"
            style={{
              backgroundImage: "url(assets/images/shape/shape-9.png)",
            }}
          ></div>
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget logo-widget">
                <figure className="footer-logo">
                  <Link to="/">
                    <img src="assets/images/ablLogo2.png" alt="" />
                  </Link>
                </figure>
                <p>
                  20th Street, Sinkor Tubman Boulevard <br />
                  Monrovia, Liberia. <br />
                  P.O.Box 1230 <br />
                  Call us 5055
                </p>

                <ul className="social-links">
                  <li>
                    <a
                      href="https://www.facebook.com/accessbankliberialimited"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://lr.linkedin.com/company/accessbank-liberia"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget links-widget ml_40">
                <div className="widget-title">
                  <h4>Explore</h4>
                </div>
                <div className="widget-content">
                  <ul className="links-list clearfix">
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    {/* <li>
                      <SmoothScrollLink to="#testimonial">
                        Testimonials
                      </SmoothScrollLink>
                    </li> */}
                    <li>
                      <Link to="/career">Careers</Link>
                    </li>
                    {/* <li>
                      <Link to="/blog">Media</Link>
                    </li> */}

                    <li>
                      <Link to="/tariff">Tarrif</Link>
                    </li>

                    <li>
                      <Link to="/faqs">FAQs</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget links-widget">
                <div className="widget-title">
                  <h4>Usefull Links</h4>
                </div>
                <div className="widget-content">
                  <ul className="links-list clearfix">
                    <li>
                      <Link to="/personal-banking">Personal Banking</Link>
                    </li>
                    <li>
                      <Link to="/business-banking">Business Banking</Link>
                    </li>
                    <li>
                      <Link to="/sme-banking">SME Banking</Link>
                    </li>

                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget contact-widget">
                <div className="widget-title">
                  <h4>Find Our Branch & ATM</h4>
                </div>
                <div className="form-inner">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="select-box">
                        <select
                          className="wide"
                          value={selectedBranch}
                          onChange={handleBranchChange}
                        >
                          <option value="">Select Branch</option>
                          {Object.entries(branches).map(
                            ([county, branchList]) => (
                              <optgroup label={county} key={county}>
                                {branchList.map((branch) => (
                                  <option value={branch.name} key={branch.name}>
                                    {branch.name}
                                  </option>
                                ))}
                              </optgroup>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="location"
                        placeholder="Location (optional)"
                        value={location}
                        onChange={handleLocationChange}
                      />
                    </div>
                    <div className="form-group">
                      <button type="submit" className="theme-btn btn-one">
                        Find on Map
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom centred">
        <div className="auto-container">
          <div className="copyright">
            <p>
              ©{getCurrentYear()} Access Bank Liberia, Powered by{" "}
              <a href="https://bigmove.dev/" target="_blank" rel="noreferrer">
                BigMove LLC.
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
